
import {javascriptGenerator, Order} from 'blockly/javascript';




const customBlocks = (Blockly) =>  {
    if (!Blockly.JavaScript) {
        Blockly.JavaScript = {};
    }
    
//! tanimlama blokları

Blockly.Blocks['tanimlama_tanimlama'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Deneyap Kart Başladığında");
        this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

// Blockly.Blocks['tanimlama_kutuphane'] = {
// init: function() {
//     this.appendDummyInput()
//         .appendField("Kütüphane")
//         .appendField(new Blockly.FieldTextInput("deneyap"), "NAME");
//     this.setTooltip("");
//     this.setHelpUrl("");
//     this.setColour("#dc143c");
// }
// };
Blockly.Blocks['tanimlama_kutuphane2'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Kütüphane ekle")
        .appendField(new Blockly.FieldTextInput("kutuphaneadi"), "kutuphane");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("Bloklarla birlikte kütüphaneler otomatik ekleniyor. Kendi kodunuzu oluşturduğunuzda veya farklı kütüphane kullanmak istediğinizde bu bloğu kullanın");
}
};
// Blockly.Blocks['tanimlama_kurulum'] = {
//     init: function() {
//         this.appendDummyInput()
//             .appendField("Kütüphane");
//         this.appendStatementInput("kutuphane")
//             .setCheck(null);
//         this.appendDummyInput()
//             .appendField("Setup");
//         this.appendStatementInput("kurulum")
//             .setCheck(null);
//         this.appendDummyInput()
//             .appendField("Loop");
//         this.appendStatementInput("dongu")
//             .setCheck(null);
//         this.setInputsInline(false);
//         this.setColour("#dc143c");
//         this.setTooltip("");
//         this.setHelpUrl("");
//     }
//     };
Blockly.Blocks['tanimlama_pindizisi'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Pin Dizisi Oluştur")
        .appendField(new Blockly.FieldTextInput("i"), "VAR")
        .appendField("Değişken Tipi")
        .appendField(new Blockly.FieldDropdown([
            ["karakter", "char"],
            ["Unsigned Long", "unsigned long"],
            ["const byte", "const byte"],
            ["int8_t", "int8_t"],
            ["uint8_t", "uint8_t"],
            ["uint16_t", "uint16_t"],
            ["metin", "String"],
            ["ikili", "byte"],
            ["byte", "byte"],
            ["tamsayı", "int"],
            ["uzun tamsayı", "long"],
            ["kesirli", "float"]
        ]), "TYPE")
        .appendField("Pin")
        .appendField(new Blockly.FieldDropdown([
            ["Dijital", "Dijital"],
            ["Analog", "Analog"]
        ]), "PIN_TYPE");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl(""); 
    }
};

Blockly.Blocks['tanimlama_kodunuyaz'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldTextInput("Loop kodunu yaz"), "kodyaz");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['tanimlama_kodunuyaz2'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldTextInput("Loop kodunu yaz"), "kodyaz2");
    this.setOutput(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};



//! mantık blokları

Blockly.Blocks['kontrol_egeryap'] = {
init: function() {
    this.appendValueInput("eger")
        .setCheck(null)
        .appendField("Eğer");
    this.appendStatementInput("yap")
        .setCheck(null)
        .appendField("Yap");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_keztekrarla'] = {
init: function() {
    this.appendValueInput("sayi")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("kez tekrarla");
    this.appendStatementInput("tekrarlanacak")
        .setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("İçerisine eklediğiniz blokları belirtilen sayı kadar döngüye sokar");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_surecetekrarla'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["sürece tekrarla",""], ["tekrarla","!"]]), "tekrarla");
    this.appendStatementInput("NAME2")
        .setCheck(null);
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['kontrol_dongudencik'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["döngüden çık (break)","break"], ["sonraki yinelemeye git (continue)","continue"]]), "NAME");
    this.setPreviousStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_ve'] = {
init: function() {
    this.appendValueInput("ilk")
        .setCheck(null);
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["ve","&&"], ["veya","||"], ["özel veya","^"], ["sola kaydırma","<<"], ["sağa kaydırma",">>"]]), "ve");
    this.appendValueInput("son")
        .setCheck(null);
    this.setOutput(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_adimlarinda'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("adımlarında");
    this.appendValueInput("2")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("ile");
    this.appendValueInput("3")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("arasında değişen")
        .appendField(new Blockly.FieldTextInput("i"), "int")
        .appendField("için");
    this.appendStatementInput("NAME2")
        .setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_sure'] = {
init: function() {
    this.appendDummyInput()
        .appendField("süre")
        .appendField(new Blockly.FieldTextInput("i"), "int")
    this.appendValueInput("1")
        .setCheck(null)
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField("dir");
    this.appendStatementInput("2")
        .setCheck(null)
        .appendField("yap");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_degil'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("değil");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['kontrol_bos'] = {
init: function() {
    this.appendDummyInput()
        .appendField("boş");
    this.setOutput(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! zaman blokları

Blockly.Blocks['zaman_bekle'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Bekle");
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["milisaniye",""], ["saniye","*1000"], ["mikro saniye","/1000"]]), "sure");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['zaman_tumu'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Tümü");
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["milisaniye",""], ["saniye","*1000"], ["mikro saniye","/1000"]]), "sure");
    this.appendStatementInput("NAME2")
        .setCheck(null)
        .appendField("yap");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['zaman_suresi'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Süresi")
        .appendField(new Blockly.FieldDropdown([["milisaniye",""], ["saniye","*1000"], ["mikro saniye","/1000"]]), "sure")
        .appendField("başlangıçtan beri");
    this.setOutput(true, null);
    this.setColour("#1e90ff");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! matematik blokları

Blockly.Blocks['matematik_indeksle'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null)
        .appendField("indeksle  ")
        .appendField("Değer");
    this.appendValueInput("2")
        .setCheck(null)
        .appendField("Başlangıçtan");
    this.appendValueInput("3")
        .setCheck(null)
        .appendField("Bitişten");
    this.appendValueInput("4")
        .setCheck(null)
        .appendField("Başlangıca");
    this.appendValueInput("5")
        .setCheck(null)
        .appendField("Bitişe");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_rasgele'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Rastgele    ")
        .appendField("buradan");
    this.appendValueInput("NAME2")
        .setCheck(null)
        .appendField("buraya");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_sayi'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldTextInput("0"), "sayi");
    this.setOutput(true, "Number");
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_aci'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldAngle(90), "aci");
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};



Blockly.Blocks['matematik_esittir'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null);
    this.appendValueInput("2")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["eşittir(==)","=="], ["eşit değildir(!=)","!="], ["küçüktür(<)","<"], ["küçük eşittir(<=)","<="], ["büyüktür(>)",">"], ["büyük eşittir(>=)",">="]]), "NAME");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_topla'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null);
    this.appendValueInput("2")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["+","+"], ["-","-"], ["x","*"], ["/","/"],["^","^"]]), "NAME");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_karekok'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["kare kök","sqrt"], ["mutlak değer","abs"], ["-","-"]]), "NAME2");
    this.setInputsInline(false);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_yuvarlama'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["yuvarlama","round"], ["yukarı yuvarla","ceil"], ["aşağı yuvarla","floor"]]), "NAME2");
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_sin'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["sin","sin"], ["cos","cos"], ["tan","tan"]]), "NAME2");
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['matematik_kalan'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null)
    this.appendValueInput("2")
        .setCheck(null)
        .appendField("mod (%)");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#4CBB17");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['matematik_hexdonustur'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("değeri HEX formatına dönüştür");
        this.appendValueInput("NAME")
            .setCheck("Number")
            .appendField("sayı");
        this.setInputsInline(true);
        this.setOutput(true, "String");
        this.setColour("#4CBB17");
        this.setTooltip("");
        this.setHelpUrl("");
    }
    };
//! metin blokları

Blockly.Blocks['metin_metin'] = {
init: function() {
    this.appendDummyInput()
        .appendField("\"")
        .appendField(new Blockly.FieldTextInput("deneyap"), "metin")
        .appendField("\"");
    this.setOutput(true, null);
    this.setColour("#9932cc");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['metin_tek'] = {
init: function() {
    this.appendDummyInput()
        .appendField("\'")
        .appendField(new Blockly.FieldTextInput("deneyap"), "metin")
        .appendField("\'");
    this.setOutput(true, null);
    this.setColour("#9932cc");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['metin_karsilastir'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null)
        .appendField("Karşılaştır");
    this.appendValueInput("2")
        .setCheck(null)
        .appendField("ve");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#9932cc");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['metin_uzunluk'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("uzunluğu");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#9932cc");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['metin_bos'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null);
    this.appendDummyInput()
        .appendField("boş");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#9932cc");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! giriş çıkış blokları

Blockly.Blocks['giris_dijitaloku'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Dijital pin oku");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#a0522d");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['giris_dijitalyaz'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Dijital pine yaz");
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["Yüksek","1"], ["Düşük","0"]]), "NAME");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#a0522d");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['giris_analogoku'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Analog pin oku");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour("#a0522d");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['giris_analogyaz'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck('Number')
        .appendField("Analog pine ")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin")
        .appendField(" yaz");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#a0522d");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['giris_yuksek'] = {
    init: function() {
        this.appendEndRowInput()
            .appendField(new Blockly.FieldDropdown([["1(Yüksek)","HIGH"], ["0(Düşük)","LOW"]]), "NAME");
        this.setOutput(true, null);
        this.setColour("#a0522d");
        this.setTooltip("");
        this.setHelpUrl("");
        }
      };
//! Algılayıcılar blokları

Blockly.Blocks['algi_ataletsel'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Ölçüm birimi oku")
        .appendField(new Blockly.FieldDropdown([["Dönüölçer","FloatGyro"], ["İvmeölçer","FloatAccel"], ["Sıcaklıkölçer","TempC"]]), "olc")
        .appendField("eksen seç")
        .appendField(new Blockly.FieldDropdown([["X","X"], ["Y","Y"], ["Z","Z"]]), "eksen");
    this.setOutput(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_hc'] = {
init: function() {
    this.appendDummyInput()
        .appendField("HC-SR04 ile mesafe ölç")
        .appendField("Tetikleyici pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "tetik")
        .appendField("Eko pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "eko");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_dht'] = {
init: function() {
    this.appendDummyInput()
        .appendField("DHT11 ile ölç")
        .appendField("pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin")
        .appendField("seç")
        .appendField(new Blockly.FieldDropdown([["Sıcaklık(C)","Temperature"], ["Nem","Humidity"]]), "sec");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_mic'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Deneyap Dahili Mikrofonu Aç");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_manyetik'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Manyetik Alan Oku");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['algi_tanimla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("TCS34725 renk algılayıcı tanımla (I2C)")
        .appendField("kazanç")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["4","4"], ["16","16"]]), "kazanc");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_oku'] = {
init: function() {
    this.appendDummyInput()
        .appendField("TCS34725 renk oku");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_dogru'] = {
init: function() {
    this.appendDummyInput()
        .appendField("TCS34725 renk doğrula")
        .appendField(new Blockly.FieldDropdown([["Kırmızı (RGB)","tcs34725_r"], ["Yeşil (RGB)","tcs34725_g"], ["Mavi (RGB)","tcs34725_b"], ["Berraklık"," tcs34725_clear"], ["Renk (HSV)","tcs34725_h"], ["Doygunluk (HSV)","tcs34725_s"], ["Ton (HSV)","tcs34725_v"]]), "NAME");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['algi_okunan'] = {
init: function() {
    this.appendDummyInput()
        .appendField("TCS34725 renk doğrula")
        .appendField("okunan renk")
        .appendField(new Blockly.FieldDropdown([["Kırmızı","2"], ["Turuncu","3"], ["Sarı","4"], ["Yeşil","5"], ["Camgöbeği","6"], ["Mavi","7"], ["Mor","8"]]), "NAME")
        .appendField("mı?");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Bluetooth blokları

Blockly.Blocks['blue_baslat'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Bluetooth bağlantısı başlat     ID");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['blue_hazir'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Bluetooth bağlantısı hazır mı?");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['blue_yaz'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Bluetooth bağlantıya yaz");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['blue_oku'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Bluetooth seri bağlantı oku");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['blue_goster'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Bluetooth bağlantıda göster");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Deneyap Led Matris

Blockly.Blocks['ledmatris_ayarla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("LED Matris Tanımla")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1")
        .appendField("Adresi Girin")
        .appendField(new Blockly.FieldTextInput("0x0A"), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['ledmatris_isik'] = {
init: function() {
    this.appendDummyInput()
        .appendField(" ")
        .appendField("0")
        .appendField(" 1")
        .appendField("  2")
        .appendField("  3")
        .appendField("  4");
    this.appendDummyInput()
        .appendField("0")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "11")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "12")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "13")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "14")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "15");
    this.appendDummyInput()
        .appendField("1")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "21")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "22")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "23")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "24")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "25");
    this.appendDummyInput()
        .appendField("2")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "31")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "32")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "33")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "34")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "35");
    this.appendDummyInput()
        .appendField("3")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "41")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "42")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "43")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "44")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "45");
    this.appendDummyInput()
        .appendField("4")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "51")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "52")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "53")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "54")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "55");
    this.appendDummyInput()
        .appendField("5")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "61")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "62")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "63")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "64")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "65");
    this.appendDummyInput()
        .appendField("6")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "71")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "72")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "73")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "74")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "75");
    this.setOutput(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['ledmatris_yak'] = {
init: function() {
    this.appendDummyInput()
        .appendField("LED Matriste Yak")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1");
    this.appendValueInput("satir")
        .setCheck(null)
        .appendField("Satır");
    this.appendValueInput("sutun")
        .setCheck(null)
        .appendField("Sütun");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['ledmatris_satir'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("LED Matris")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "deger")
        .appendField("Satır Seç");
    this.appendDummyInput()
        .appendField("LED Yak")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "1")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "2")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "3")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "4")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "5");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['ledmatris_sutun'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("LED Matris")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "deger")
        .appendField("Sütun Seç");
    this.appendDummyInput()
        .appendField("LED Yak")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "1")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "2")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "3")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "4")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "5")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "6")
        .appendField(new Blockly.FieldCheckbox("TRUE"), "7");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Deneyap Oled ekran blokları

Blockly.Blocks['oled_ayarla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("OLED Ekran Tanımla")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1")
        .appendField("Adresi Girin")
        .appendField(new Blockly.FieldTextInput("0x7A"), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['oled_goster'] = {
init: function() {
    this.appendDummyInput()
        .appendField("OLED Ekranda Göster")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1");
    this.appendValueInput("satir")
        .setCheck(null)
        .appendField("Satır");
    this.appendValueInput("sutun")
        .setCheck(null)
        .appendField("Sütun");
    this.appendValueInput("metin")
        .setCheck("String")
        .appendField("Metin");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['oled_kayan'] = {
init: function() {
    this.appendDummyInput()
        .appendField("OLED Ekranda Kayan Yazı")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1")
        .appendField("Yön Seç")
        .appendField(new Blockly.FieldDropdown([["Soldan Sağa","Scroll_Right"], ["Sağdan Sola","Scroll_Left"]]), "yon")
        .appendField("Hız Seç")
        .appendField(new Blockly.FieldDropdown([["2","2"], ["3","3"], ["4","4"], ["5","5"], ["25","25"], ["64","64"], ["128","128"], ["256","256"]]), "hiz");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['oled_veri'] = {
init: function() {
    this.appendDummyInput()
        .appendField("OLED Ekranda Göster")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1");
    this.appendValueInput("satir")
        .setCheck(null)
        .appendField("Satır");
    this.appendValueInput("sutun")
        .setCheck(null)
        .appendField("Sütun");
    this.appendDummyInput()
        .appendField("Veri Tipi Seç")
        .appendField(new Blockly.FieldDropdown([["Tamsayı",".putInt("], ["Ondalık Sayı",".putFloat("]]), "veri");
    this.appendValueInput("degisken")
        .setCheck(null)
        .appendField("Değişken");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['oled_temizle'] = {
init: function() {
    this.appendDummyInput()
        .appendField("OLED Ekran Temizle")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Deneyap Algılayıcılar blokları

Blockly.Blocks['dealgi_basinc'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Basınç Ölçer Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_basincolcer'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Basınçölçer'den Veri Oku")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["Atmosferik Basınç","BaroSensor.getPressure()"], ["Sıcaklık","BaroSensor.getTemp()"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_sicaklik'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Sıcaklık, Nem ve Basınç Ölçer Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_sicaklikbirim'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Sıcaklık, Nem ve Basınç Ölçer'den Sıcaklık Oku")
          .appendField("Birim Seç")
          .appendField(new Blockly.FieldDropdown([["Celsius","TempHum.TtoDegC()"], ["Fahrenheit","TempHum.TtoDegF()"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_sicaklikoku'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Sıcaklık, Nem ve Basınç Ölçer'den Bağıl Nem Oku");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_3eksen'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("3-Eksen Kumpas Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kumpas'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kumpas Oku")
          .appendField("Eksen Seç")
          .appendField(new Blockly.FieldDropdown([["X","Magne.readMagnetometerX()"], ["Y","Magne.readMagnetometerY()"], ["Z","Magne.readMagnetometerZ()"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_6eksen'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("6-Eksen Ataletsel Ölçüm Birim'i Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"]]), "tanimla")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x6B"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_donu'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Dönüölçer Oku")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"]]), "oku")
          .appendField("Eksen Seç")
          .appendField(new Blockly.FieldDropdown([["X","X"], ["Y","Y"], ["Z","Z"]]), "eksen");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_ivme'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("İvmeölçer Oku")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"]]), "oku")
          .appendField("Eksen Seç")
          .appendField(new Blockly.FieldDropdown([["X","X"], ["Y","Y"], ["Z","Z"]]), "eksen");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_ataletsel'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("6-Eksen Ataletsel Ölçüm Birim'inden Sıcaklık Oku")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"]]), "sicaklik")
          .appendField("Birim Seç")
          .appendField(new Blockly.FieldDropdown([["Celsius","C"], ["Fahrenheit","F"]]), "birim");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_hareket'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Hareket, Işık, Renk ve Mesafe Algılama Tanımla")
          .appendField(new Blockly.FieldDropdown([["Hareket Algıla","hareket"], ["Mesafe Ölç","mesafe"], ["Renk ve Işık Ayarla","renk"]]), "NAME");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_yakinlik'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Yakınlık Tespiti");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_harekettespit'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Hareket Tespiti");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_yon'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Yön Seç")
          .appendField(new Blockly.FieldDropdown([["Aşağı","APDS9960_DOWN"], ["Yukarı","APDS9960_UP"], ["Sağ","APDS9960_RIGHT"], ["Sol","APDS9960_LEFT"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_renk'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Renk ya da Ortam Işığı Seç")
          .appendField(new Blockly.FieldDropdown([["Kırmızı","r"], ["Yeşil","g"], ["Mavi","b"], ["Ortam Işığı","c"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_derinlik'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Derinlik Ölçer Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_derinlikolc'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Derinlik Ölç");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_duman'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Duman Dedektörü Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "dedektor")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x20"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_dumanalgila'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Duman Algıla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "duman")
          .appendField("Tip Seç")
          .appendField(new Blockly.FieldDropdown([["Analog","Analog"], ["Dijital","Digital"]]), "tip");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kumanda'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kumanda Kolu Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "tanimla")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x1A"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kumandakontrol'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kumanda Kolu Kontrol Et")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "kumanda")
          .appendField("Tip Seç")
          .appendField(new Blockly.FieldDropdown([["X Ekseni",".xRead()"], ["Y Ekseni",".yRead()"], ["Buton",".swRead()"]]), "tip");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_cift'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Çift Kanallı Çizgi Algılayıcı Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "tanimla")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x07"), "NAME");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_cizgi'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Çizgi Algıla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "cizgi")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["Analog","Analog"], ["Dijital","Digital"]]), "tip")
          .appendField("Algılayıcı Seç")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"]]), "algi");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_mesafe'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Mesafe Ölçer ve Işık Algılayıcı Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_yakinlikisik'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Yakınlık ya da Işık Yoğunluğu Algıla")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["Yakınlık","YakinIsik.getPSvalue()"], ["Işık","YakinIsik.getLuxValue()"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_yagmur'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Yağmur Algılayıcı Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "1")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x2E"), "NAME");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_yagmurveri'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Yağmur Algıla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["Analog","Analog"], ["Dijital","Digital"]]), "tip");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_role'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Röle Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x0C"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_rolecalistir'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Röle Çalıştır")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Durum")
          .appendField(new Blockly.FieldDropdown([["Aç","1"], ["Kapat","0"]]), "durum");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_toprak'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Toprak Nemi Algılayıcısı Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adres")
          .appendField(new Blockly.FieldTextInput("0x0F"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_toprakolc'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Toprak Nemi Ölç")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_dokunmatik'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Dokunmatik Tuş Takımı Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adres")
          .appendField(new Blockly.FieldTextInput("0x0E"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_tus'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Tuşu Oku")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kizil'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kızılötesi Alıcı Verici Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adres")
          .appendField(new Blockly.FieldTextInput("0x22"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kiziloku'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kızılötesi Oku")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_kizilgonder'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kızılötesi Gönder")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Sinyal")
          .appendField(new Blockly.FieldTextInput("0x2A"), "sinyal");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_hareketadres'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Hareket Algılayıcı Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x32"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_hareketalgila'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Hareketi Algıla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_zaman'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Zamanı Yeniden Ayarla");
      this.appendValueInput("gun")
          .setCheck(null)
          .appendField("Gün");
      this.appendValueInput("ay")
          .setCheck(null)
          .appendField("Ay");
      this.appendValueInput("yil")
          .setCheck(null)
          .appendField("Yıl");
      this.appendValueInput("saat")
          .setCheck(null)
          .appendField("Saat");
      this.appendValueInput("dakika")
          .setCheck(null)
          .appendField("Dakika");
      this.appendValueInput("saniye")
          .setCheck(null)
          .appendField("Saniye");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_simdiki'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Şimdiki Zamanı Tanımla");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_zamangoster'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Zamanı Göster")
          .appendField(new Blockly.FieldDropdown([["Yıl","now.year()"], ["Ay","now.month()"], ["Gün","now.day()"], ["Saat","now.hour()"], ["Dakika","now.minute()"], ["Saniye","now.second()"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_zamantarih'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Zamanı")
          .appendField(new Blockly.FieldDropdown([["Tarih","tarih"], ["Saat","saat"]]), "NAME")
          .appendField("olarak göster");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_dc'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("DC Motor Sürücü Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x16"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_dcsur'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("DC Motor Sür")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "sur")
          .appendField("Kanal")
          .appendField(new Blockly.FieldDropdown([["A","MOTOR1"], ["B","MOTOR2"]]), "kanal");
      this.appendValueInput("NAME")
          .setCheck(null)
          .appendField("Hız %");
      this.appendDummyInput()
          .appendField("Yön")
          .appendField(new Blockly.FieldDropdown([["İleri","1"], ["Geri","0"]]), "yon");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_dcsurucu'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("DC Motor Sürücü")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "sur")
          .appendField("Durum")
          .appendField(new Blockly.FieldDropdown([["Aktif","ENABLE"], ["Pasif","DISABLE"]]), "durum");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_step'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Step Motor Sürücü Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "sur")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x16"), "adres");
      this.appendValueInput("tur")
          .setCheck(null)
          .appendField("Tam Tur Adım Miktarı");
      this.appendValueInput("hiz")
          .setCheck(null)
          .appendField("Hız");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['dealgi_stepsur'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Step Motor Sür")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "sur");
      this.appendValueInput("adim")
          .setCheck(null)
          .appendField("Adım");
      this.appendDummyInput()
          .appendField("Yön")
          .appendField(new Blockly.FieldDropdown([["İleri",""], ["Geri","-"]]), "yon");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_stephiz'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Step Motor Hızı Belirle")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "sur");
      this.appendValueInput("hiz")
          .setCheck(null)
          .appendField("Hız");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_uv'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("UV Algılayıcı Tanımla")
          .appendField("Tip Seç")
          .appendField(new Blockly.FieldDropdown([["UV","UVS"], ["Görünür Işık","ALS"]]), "tip");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_uvalgila'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("UV/Görünür Işık Algıla")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["UVI","UVlight.getUVI()"], ["UVS","UVlight.readUVS()"], ["LUX","UVlight.getLUX()"], ["ALS","UVlight.readALS()"]]), "tip");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_mikrofon'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Mikrofon Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x35"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_ses'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Ses Şiddeti Ölç")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_gps'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("GPS Tanımla")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "gps")
          .appendField("Adresi Girin")
          .appendField(new Blockly.FieldTextInput("0x2F"), "adres");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['dealgi_konum'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Konum Belirle")
          .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "gps")
          .appendField("Veri Tipi Seç")
          .appendField(new Blockly.FieldDropdown([["Enlem",".ReadLocationLat()"], ["Boylam",".ReadLocationLng()"], ["Gün",".ReadDay()"], ["Ay",".ReadMonth()"], ["Yıl",".ReadYear()"], ["Saat",".ReadHour()"], ["Dakika",".ReadMinute()"], ["Saniye",".ReadSecond()"]]), "tip");
      this.setOutput(true, null);
      this.setColour("#dc143c");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };































//! Dizi blokları




















//! Kızılötesi blokları

Blockly.Blocks['kizil_oku'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Kızılötesi kumanda oku")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "NAME");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! LCD I2C blokları

Blockly.Blocks['lcd_ekran'] = {
init: function() {
    this.appendValueInput("sutun")
        .setCheck(null)
        .appendField("LCD ekran ayarla")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "ekran")
        .appendField("Adres")
        .appendField(new Blockly.FieldTextInput("0x20"), "adres")
        .appendField("Sütun");
    this.appendValueInput("satir")
        .setCheck(null)
        .appendField("Satır");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_imlec'] = {
    init: function() {
    this.appendValueInput("1")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C imleç ayarla")
        .appendField("Sütun");
    this.appendValueInput("2")
        .setCheck(null)
        .appendField("Satır");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
    }
};
Blockly.Blocks['lcd_goster'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C Göster")
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_yaz'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck(null)
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C Yaz")
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_temizle'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C temizle");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_ev'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C imleç başlangıca(0,0) gelsin");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_display'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C")
        .appendField(new Blockly.FieldDropdown([["Ekranı Göster","display"], ["Ekranı Gizle","noDisplay"]]), "display");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_kaydir'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C içeriği")
        .appendField(new Blockly.FieldDropdown([["Sola Kaydır","Left"], ["Sağa Kaydır","Right"]]), "kaydir");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_arka'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C arka ışığı")
        .appendField(new Blockly.FieldDropdown([["Aç",".backlight();"], ["Kapat",".noBacklight();"]]), "isik");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_imlecgoster'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C imleci")
        .appendField(new Blockly.FieldDropdown([["Göster",".cursor();"], ["Gizle",".noCursor();"]]), "imlec");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['lcd_imlecyan'] = {
init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("LCD I2C imleç yanıp sönme")
        .appendField(new Blockly.FieldDropdown([["Aç",".blink();"], ["Kapat",".noBlink();"]]), "imlec");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Nesnelerin interneti blokları



















//! RFID blokları

Blockly.Blocks['rfid_tanimla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("MFRC522 RFID okuyucu tanımla")
        .appendField("SDA[CS] pini")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "1")
        .appendField("sıfırlama pini")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "2");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rfid_oku'] = {
init: function() {
    this.appendDummyInput()
        .appendField("ID Oku");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rfid_algi'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Bu RFID kartı algılandı mı?");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! RGB LED blokları

Blockly.Blocks['rgb_yan'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Dahili LED yanıp söner")
        .appendField(new Blockly.FieldDropdown([["yavaşça","1000"], ["hızlı","100"]]), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rgb_rgb'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Dahili RGB LED");
    this.appendValueInput("kirmizi")
        .setCheck(null)
        .appendField("Kırmızı (0-255)");
    this.appendValueInput("yesil")
        .setCheck(null)
        .appendField("Yeşil (0-255)");
    this.appendValueInput("mavi")
        .setCheck(null)
        .appendField("Mavi (0-255)");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rgb_adres'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Dahili Adreslenebilir RGB LED");
    this.appendValueInput("kirmizi")
        .setCheck(null)
        .appendField("Kırmızı (0-255)");
    this.appendValueInput("yesil")
        .setCheck(null)
        .appendField("Yeşil (0-255)");
    this.appendValueInput("mavi")
        .setCheck(null)
        .appendField("Mavi (0-255)");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#dc143c");
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['rgb_tanimla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("RGB LED Tanımla")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "tanim")
        .appendField("Kırmızı LED Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "kirmizi")
        .appendField("Yeşil LED Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "yesil")
        .appendField("Mavi LED Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "mavi");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rgb_yak'] = {
init: function() {
    this.appendDummyInput()
        .appendField("RGB LED Yak")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "tanim");
    this.appendValueInput("kirmizi")
        .setCheck(null)
        .appendField("Kırmızı (0-255)");
    this.appendValueInput("yesil")
        .setCheck(null)
        .appendField("Yeşil (0-255)");
    this.appendValueInput("mavi")
        .setCheck(null)
        .appendField("Mavi (0-255)");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['rgb_sec'] = {
init: function() {
    this.appendDummyInput()
        .appendField("RGB LED Tanımlananı Seç")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "NAME")
        .appendField("Renk Seç")
        .appendField(new Blockly.FieldColour("#ff0000"), "renk");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Saat blokları

Blockly.Blocks['saat_zamanayarla'] = {
init: function() {
    this.appendValueInput("yil")
        .setCheck(null)
        .appendField("Zamanı ayarla  Yıl");
    this.appendValueInput("ay")
        .setCheck(null)
        .appendField("Ay");
    this.appendValueInput("gun")
        .setCheck(null)
        .appendField("Gün");
    this.appendValueInput("saat")
        .setCheck(null)
        .appendField("Saat");
    this.appendValueInput("dakika")
        .setCheck(null)
        .appendField("Dakika");
    this.appendValueInput("saniye")
        .setCheck(null)
        .appendField("Saniye");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.Blocks['saat_zamanyenile'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Zamanı Yenile");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['saat_zamangosteryil'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Zamanı göster")
        .appendField(new Blockly.FieldDropdown([["Yıl","year"], ["Ay","month"], ["Gün","day"], ["Saat","hour"], ["Dakika","minute"], ["Saniye","second"]]), "NAME");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['saat_tarihgoster'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Tarihi göster");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['saat_zamangoster'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Zamanı göster");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! SD Kart blokları

Blockly.Blocks['sd_baslat'] = {
init: function() {
    this.appendDummyInput()
        .appendField("SD Kart Başlat");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};

Blockly.Blocks['sd_yaz'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck("String")
        .appendField("Bu Dosyaya");
    this.appendValueInput("2")
        .setCheck("String")
        .appendField("Bunu yaz");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};
Blockly.Blocks['sd_olustur'] = {
init: function() {
    this.appendValueInput("1")
        .setCheck("String")
        .appendField("SD Kartta Dosya Oluştur. Dosya adı:");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};

//! Seri iletişim blokları

Blockly.Blocks['seri_baslat'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Seri bağlantı başlat");
    this.appendDummyInput()
        .appendField("Baud Hızı")
        .appendField(new Blockly.FieldDropdown([["1200","1200"], ["2400","2400"], ["4800","4800"], ["9600","9600"], ["19200","19200"], ["38400","38400"], ["57600","57600"], ["74880","74880"], ["115200","115200"], ["230400","230400"], ["250000","250000"], ["500000","500000"], ["1000000","1000000"], ["2000000","2000000"]]), "hiz");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_hazir'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Seri bağlantı hazır mı?");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_goster'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Seri monitörde göster");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['seri_oku'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Seri bağlantı oku");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_yaz'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Seri bağlantıyı yaz");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_habertanimla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("I2C Haberleşmesi Tanımla");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_haberbaslat'] = {
init: function() {
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("I2C Haberleşmesi Başlat     Adres");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_haberbitir'] = {
init: function() {
    this.appendDummyInput()
        .appendField("I2C Haberleşmesi Bitir");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['seri_haberdurdur'] = {
init: function() {
    this.appendDummyInput()
        .appendField("I2C Haberleşmesi Durdur");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
    }
};

//! Servo blokları

Blockly.Blocks['servo_dondur'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Döndür");
    this.appendDummyInput()
        .appendField("Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin");
    this.appendDummyInput()
        .appendField("Kanal")
        .appendField(new Blockly.FieldDropdown([["0","0"], ["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"], ["9","9"]]), "kanal");
    this.appendValueInput("aci")
        .setCheck(null)
        .appendField("Açı [0-180]");
    this.appendValueInput("bekle")
        .setCheck(null)
        .appendField("Bekle");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

//! Ses blokları

Blockly.Blocks['ses_cal'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Çal");
    this.appendDummyInput()
        .appendField("pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin");
    this.appendValueInput("Hz")
        .setCheck(null)
        .appendField("Hz");
    this.appendValueInput("sure")
        .setCheck(null)
        .appendField("süre(ms)");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['ses_notacal'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Nota çal");
    this.appendDummyInput()
        .appendField("Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin");
    this.appendDummyInput()
        .appendField("Nota")
        .appendField(new Blockly.FieldDropdown([["DO","523"], ["RE","587"], ["Mİ","659"], ["FA","698"], ["SOL","784"], ["LA","880"], ["Sİ","988"]]), "nota");
    this.appendDummyInput()
        .appendField("Süre(ms)")
        .appendField(new Blockly.FieldDropdown([["125","125"], ["250","250"], ["500","500"], ["1000","1000"], ["2000","2000"]]), "sure");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['ses_sustur'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Sustur");
    this.appendDummyInput()
        .appendField("pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['ses_nota'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Nota")
        .appendField(new Blockly.FieldDropdown([["DO","523"], ["DO#","554"], ["RE","587"], ["RE#","622"], ["Mİ","659"], ["FA","698"], ["FA#","740"], ["SOL","784"], ["SOL#","830"], ["LA","880"], ["LA#","932"], ["Sİ","988"]]), "nota");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};

Blockly.Blocks['ses_hoparlor'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Hoparlör Tanımla");
    this.appendDummyInput()
        .appendField("Pin")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "pin");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};
Blockly.Blocks['ses_muzik'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Müzik Çal");
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Notalar");
    this.appendDummyInput()
        .appendField("Enstruman")
        .appendField(new Blockly.FieldDropdown([["Piano","PIANO"], ["Klavsen","HARPSICHORD"], ["Org","ORGAN"], ["Saksafon","SAXOPHONE"]]), "Enstruman");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};

//! Step Blokları

Blockly.Blocks['step_lm298tanimla'] = {
init: function() {
    this.appendDummyInput()
        .appendField("L298N Tanımla")
        .appendField("Kanal A")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "1")
        .appendField("Giriş 1")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "2")
        .appendField("Giriş 2")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "3")
        .appendField("Kanal B")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "4")
        .appendField("Giriş 3")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "5")
        .appendField("Giriş 4")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "6");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
    }
  };
Blockly.Blocks['step_lm298nsur'] = {
init: function() {
    this.appendDummyInput()
        .appendField("L298N sür");
    this.appendDummyInput()
        .appendField("Yön")
        .appendField(new Blockly.FieldDropdown([["İleri","ileri"], ["Geri","geri"]]), "1")
        .appendField("Kanal")
        .appendField(new Blockly.FieldDropdown([["A","A"], ["B","B"]]), "2");
    this.appendValueInput("hiz")
        .setCheck(null)
        .appendField("Hız");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
}
};
Blockly.Blocks['step_kurulum'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Step motor tanımla");
    this.appendDummyInput()
        .appendField("ID seç")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"]]), "NAME");
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Adım/Tur");
    this.appendDummyInput()
        .appendField("Giriş 1")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "1");
    this.appendDummyInput()
        .appendField("Giriş 2")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "2");
    this.appendDummyInput()
        .appendField("Giriş 3")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "3");
    this.appendDummyInput()
        .appendField("Giriş 4")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "4");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};
Blockly.Blocks['step_sur'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Step motor sür")
        .appendField("ID seç")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"]]), "NAME");
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Hız (rpm)");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};

Blockly.Blocks['step_adim'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Step motor sür")
        .appendField("ID seç")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"]]), "NAME");
    this.appendValueInput("NAME")
        .setCheck(null)
        .appendField("Adım");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};

//! Tuş blokları

Blockly.Blocks['tus_3x4'] = {
init: function() {
    this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField("Tuş Takımı (3x4) Pinleri seçin")
        .appendField("1.Satır")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "1")
        .appendField("2.Satır")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "2")
        .appendField("3.Satır")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "3")
        .appendField("4.Satır")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "4")
        .appendField("1.Sütun")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "5")
        .appendField("2.Sütun")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "6")
        .appendField("3.Sütun")
        .appendField(new Blockly.FieldDropdown([["D0","D0"], ["D1","D1"], ["D2 (TX)","TX"], ["D3(RX)","RX"], ["D4","D4"], ["D5 (SCK)","SCK"], ["D6(MISO)","MISO"], ["D7 (MOSI)","MOSI"], ["D8","D8"], ["D9","D9"], ["D10 (SDA)","SDA"], ["D11 (SCL)","SCL"], ["D12","D12"], ["D13","D13"], ["D14","D14"], ["D15","D15"], ["A4","A4"], ["A5","A5"], ["DAC1","DAC1"], ["DAC2","DAC2"]]), "7");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};
Blockly.Blocks['tus_basilan'] = {
init: function() {
    this.appendDummyInput()
        .appendField("Basılan Tuş");
    this.setOutput(true, null);
    this.setColour(230);
this.setTooltip("");
this.setHelpUrl("");
}
};
  
Blockly.Blocks['degisken_olustur'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Yeni Değişken Oluştur:")
            .appendField(new Blockly.FieldTextInput("degisken_adi"), "NAME")
            .appendField("Değişkenin Tipi")
            .appendField(new Blockly.FieldDropdown([
                ["tamsayı", "int"],
                ["karakter", "char"],
                ["Unsigned Long", "unsigned long"],
                ["const byte", "const byte"],
                ["int8_t", "int8_t"],
                ["uint8_t", "uint8_t"],
                ["uint16_t", "uint16_t"],
                ["metin", "String"],
                ["ikili", "byte"],
                ["byte", "byte"],
                ["uzun tamsayı", "long"],
                ["kesirli", "float"]
            ]), "TYPE");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#a55b80");
        this.setTooltip("");
        this.setHelpUrl(""); 
        }
    };
  
    Blockly.Blocks['degisken_yap'] = {
        init: function() {
          this.appendValueInput("NAME")
              .setCheck(null)
              .appendField(new Blockly.FieldTextInput("degisken_adi"), "NAME")
              .appendField("adlı değişkenin değerini");
          this.appendDummyInput()
              .appendField("yap");
          this.setInputsInline(true);
          this.setPreviousStatement(true, null);
          this.setNextStatement(true, null);
          this.setColour("#a55b80");
       this.setTooltip("");
       this.setHelpUrl("");
        }
      };

//! tanimlama generatörleri

javascriptGenerator.forBlock['tanimlama_tanimlama'] = function(block, generator) {

    var statements_tanimlama = generator.statementToCode(block, 'tanimlama');
    var code = '';
    return code;
};
// javascriptGenerator.forBlock['tanimlama_kutuphane'] = function(block, generator) {
//     var text_name = block.getFieldValue('NAME');
//     // Kütüphane bloğu
//     var code = '#include <' + text_name + '.h> \n';
//     return code;
//   };
javascriptGenerator.forBlock['tanimlama_kutuphane2'] = function(block, generator) {
    var text_name = block.getFieldValue('kutuphane');
    // Kütüphane bloğu
    var code = '½½#include <' + text_name + '.h> ½½';
    return code;
};
// javascriptGenerator.forBlock['tanimlama_kurulum'] = function(block, generator) {

//     var statements_kutuphane = generator.statementToCode(block, 'kutuphane');
//     var statements_kurulum = generator.statementToCode(block, 'kurulum');
//     var statements_dongu = generator.statementToCode(block, 'dongu');
//     var code ='//Kütüphaneler\n'+ statements_kutuphane +'void setup() {\n' + statements_kurulum + '}\n\n'+'void loop() {\n' + statements_dongu + '}\n\n';
//     return code;
// };
  javascriptGenerator.forBlock['tanimlama_pindizisi'] = function(block, generator) {
    var variable_name = block.getFieldValue('VAR');
    var variable_type = block.getFieldValue('TYPE');
    var pin_type = block.getFieldValue('PIN_TYPE');
    var code = '';

    if (pin_type === 'Dijital') {
        code = variable_type +' ' + variable_name + '[16] = {D0,D1,D2,D3,D4,D5,D6,D7,D8,D9,D10,D11,D12,D13,D14,D15}';
    } else if (pin_type === 'Analog') {
        code = variable_type +' ' + variable_name + '[6] = {A0,A1,A2,A3,A4,A5}';
    }
    code="½½"+code+"½½";
    return code;
};

javascriptGenerator.forBlock['tanimlama_kodunuyaz'] = function(block, generator) {
    var text_kodyaz = block.getFieldValue('kodyaz');
    var code = ""+text_kodyaz + ";";
    return code ;
};

javascriptGenerator.forBlock['tanimlama_kodunuyaz2'] = function(block, generator) {
    var text_kodyaz2 = block.getFieldValue('kodyaz2');
    var code = text_kodyaz2;
    return [code, Order.NONE]
};




//! mantık generatörleri
  
javascriptGenerator.forBlock['kontrol_egeryap'] = function(block, generator) {
    var value_eger = generator.valueToCode(block, 'eger', Order.NONE);
    var statements_yap = generator.statementToCode(block, 'yap');
    var code = "if (" + value_eger + ") {\n" + statements_yap + "}";
    return code;
  };
  
javascriptGenerator.forBlock['kontrol_keztekrarla'] = function(block, generator) {
    var value_sayi = generator.valueToCode(block, 'sayi', Order.NONE);
    var statements_tekrarlanacak = generator.statementToCode(block, 'tekrarlanacak');
    var code = 'for (int i = 0; i < ' + value_sayi + '; i++) {\n' + statements_tekrarlanacak + '}\n';
    return code;
  };
  
  javascriptGenerator.forBlock['kontrol_surecetekrarla'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_tekrarla = block.getFieldValue('tekrarla');
    var statements_name = generator.statementToCode(block, 'NAME2');
    var code = 'while (' + dropdown_tekrarla + value_name+')' +'{\n' + statements_name + '}\n';
    return code;
  };
  
  javascriptGenerator.forBlock['kontrol_dongudencik'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name+ ';\n';
    return code;
  };
  


  javascriptGenerator.forBlock['kontrol_ve'] = function(block, generator) {
    var value_ilk = generator.valueToCode(block, 'ilk', Order.NONE);
    var dropdown_ve = block.getFieldValue('ve');
    var value_son = generator.valueToCode(block, 'son', Order.NONE);
    // Mantıksal AND, OR ve diğer operasyonlar
    var code = '(' + value_ilk + ' ' + dropdown_ve + ' ' + value_son + ')';
    return [code, Order.NONE];
  };
  
//   javascriptGenerator.forBlock['kontrol_adimlarinda'] = function(block, generator) {
//     var value_1 = generator.valueToCode(block, '1', Order.NONE);
//     var value_2 = generator.valueToCode(block, '2', Order.NONE);
//     var value_3 = generator.valueToCode(block, '3', Order.NONE);
//     var variable_name = block.getField('int').getText();
//     var statements_name = generator.statementToCode(block, 'NAME2');
//     // Belirli adımlarda bir değişken için tekrarla bloğu
//     var code = 'for (int ' + variable_name + ' = ' + value_1 + '; ' + variable_name + ' >= ' + value_2 + '; ' + variable_name + ' += ' + value_3 + ') {\n' + statements_name + '}\n';
//     return code;
//   };
  
//   javascriptGenerator.forBlock['kontrol_sure'] = function(block, generator) {
//     var variable_name = block.getField('int').getText();
//     var value_1 = generator.valueToCode(block, '1', Order.NONE);
//     var statements_2 = generator.statementToCode(block, '2');
//     // Belirli bir süre boyunca döngüde kalma bloğu
//     var code = 'for (var ' + variable_name + ' = 0; ' + variable_name + ' < ' + value_1 + '; ' + variable_name + '++) {\n' + statements_2 + '}\n';
//     return code;
//   };
  
  javascriptGenerator.forBlock['kontrol_degil'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // NOT işlemi uygulayarak değeri tersine çevir
    var code = '!(' + value_name + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['kontrol_bos'] = function(block, generator) {
    // Boş bir değer döndür
    var code = 'null';
    return [code, Order.NONE];
  };
  

  //! zaman generatörleri
  javascriptGenerator.forBlock['zaman_bekle'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_sure = block.getFieldValue('sure');
    // Belirtilen süre boyunca bekle

    var code = 'delay(' + value_name + dropdown_sure +');\n';
    return code;
  };
  
  javascriptGenerator.forBlock['zaman_tumu'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_sure = block.getFieldValue('sure');
    var statements_name = generator.statementToCode(block, 'NAME2');
    // Belirtilen süre boyunca döngüyü yürüt
    
    var code = "½½long temps"+value_name+"=0;½½"+"if((millis()-temps"+value_name+")>="+value_name+dropdown_sure+") {\ntemps"+value_name+"=millis();\n"+statements_name+"\n}";
    return code;
  };
  
  javascriptGenerator.forBlock['zaman_suresi'] = function(block, generator) {
    var dropdown_sure = block.getFieldValue('sure');
    // Belirtilen zaman diliminde geçen süreyi döndür
    var code = 'millis()'+ dropdown_sure;
    return [code, Order.NONE];
  };
  


    //!  Matematik generatorleri
  javascriptGenerator.forBlock['matematik_indeksle'] = function(block, generator) {
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    var value_3 = generator.valueToCode(block, '3', Order.NONE);
    var value_4 = generator.valueToCode(block, '4', Order.NONE);
    var value_5 = generator.valueToCode(block, '5', Order.NONE);
    // İndeksleme işlemi
    var code = 'map(' + value_1 + ',' + value_2 + ',' + value_3 + ',' + value_4 + ',' + value_5 + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_rasgele'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);
    // Rastgele sayı oluştur
    var include="½½long math_random_int(int a,int b) {½½ ½½if(a > b) {½½ ½½int c = a;½½ ½½a = b;½½ ½½b = c;½½ ½½}½½ ½½return random(a,b+1);½½ ½½}½½";
    var code = include+"math_random_int(" + value_name + "," + value_name2 + ")";
    return [code, Order.NONE];
  };
  

  javascriptGenerator.forBlock['matematik_sayi'] = function(block, generator) {
    var sayi = generator.valueToCode(block, 'sayi', Order.NONE);
    var degiskenAdi = block.getFieldValue('sayi');
    var code=degiskenAdi;
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_aci'] = function(block, generator) {
    var angle_aci = generator.valueToCode(block, 'aci', Order.NONE);
    var degiskenAdi = block.getFieldValue('aci');
    var code = angle_aci + degiskenAdi;
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_esittir'] = function(block, generator) {
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME');
    var code = '(' + value_1 + ' ' + dropdown_name + ' ' + value_2 + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_topla'] = function(block, generator) {
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME');
    var code = '(' + value_1 + ' ' + dropdown_name + ' ' + value_2 + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_karekok'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME2');
    // Kök alma işlemi
    var code = dropdown_name + '('+value_name+ ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_yuvarlama'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME2');
    // Yuvarlama işlemi
    var code = dropdown_name + '(' + value_name + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_sin'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME2');
    // Trigonometrik fonksiyonlar
    var code = dropdown_name + '(' + value_name + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_kalan'] = function(block, generator) {
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    // Mod alma işlemi
    var code = '(' + value_1 + ' % ' + value_2 + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['matematik_hexdonustur'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = value_name + ',HEX';
    return [code, Order.NONE]
};
  //! metin generatörleri

  javascriptGenerator.forBlock['metin_metin'] = function(block, generator) {
    var text_metin = block.getFieldValue('metin');
    // Metin değerini döndür
    var code = '"' + text_metin + '"';
    return [code, Order.NONE];
  };
  javascriptGenerator.forBlock['metin_tek'] = function(block, generator) {
    var text_metin = block.getFieldValue('metin');
    // Metin değerini döndür
    var code = "'" + text_metin + "'";
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['metin_karsilastir'] = function(block, generator) {
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    // Metin karşılaştırma işlemi
    var code = 'strcmp(' + value_1 + ',' + value_2 + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['metin_uzunluk'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // Metin uzunluğunu döndür
    var code = 'String(' + value_name + ').length()';
    return [code, Order.NONE];
  };
  javascriptGenerator.forBlock['metin_bos'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // Metin uzunluğunu döndür
    var code = '½½boolean isStringEmpty(String msg) {½½ ½½if (msg.length() == 0) {½½ ½½return true;½½ ½½} else {½½ ½½return false;½½ ½½} ½½ ½½} ½½'+'isStringEmpty(String(' + value_name + ')';
    return [code, Order.NONE];
  };
  

//! Değişken generatörleri
javascriptGenerator.forBlock['degisken_olustur'] = function(block, generator) {
    var variable_name = block.getFieldValue('NAME');
    var variable_type = block.getFieldValue('TYPE');

    var code = variable_type +' ' + variable_name+";" ;

    code="½½"+code+"½½";
    return code;
};
javascriptGenerator.forBlock['degisken_yap'] = function(block, generator) {
    var text_name = block.getFieldValue('NAME');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // TODO: Assemble javascript into code variable.
    var code = "½½"+text_name+"="+value_name+";½½";
    return code;
  };

  //! giris/çıkış generatörleri

  javascriptGenerator.forBlock['giris_dijitaloku'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // Dijital pin okuma işlemi
    var code = '{{pinMode('+value_name+', INPUT);{{'+'digitalRead(' + value_name + ')';
    return [code, Order.NONE];
  };
  
  javascriptGenerator.forBlock['giris_dijitalyaz'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_name = block.getFieldValue('NAME');
    // Dijital pine yazma işlemi
    var code = '{{pinMode('+value_name+', OUTPUT);{{'+'digitalWrite(' + value_name + ', ' + dropdown_name + ');\n';
    return code;
  };
  
  javascriptGenerator.forBlock['giris_analogoku'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    // Analog pin okuma işlemi
    var code = '{{pinMode('+value_name+', INPUT);{{'+'analogRead(' + value_name + ')';
    return [code, Order.NONE];
  };

javascriptGenerator.forBlock['giris_analogyaz'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_name = block.getFieldValue('pin');
    // Arduino kodunu oluştur
    var code = '{{pinMode('+dropdown_name+', OUTPUT);{{'+'analogWrite(' + dropdown_name + ', ' + value_name + ');\n';
    return code;
};
javascriptGenerator.forBlock['giris_yuksek'] = function(block, generator) {
    var value = block.getFieldValue('NAME');
    var code = value ;
    return [code, Order.NONE];
  };

//! Algı generatörleri

javascriptGenerator.forBlock['algi_ataletsel'] = function(block, generator) {
    var dropdown_olc = block.getFieldValue('olc');
    var dropdown_eksen = block.getFieldValue('eksen');
    var include="½½#include <lsm6dsm.h>½½ ½½LSM6DSM IMU;½½";
    var setup="{{Serial.begin(115200);{{ {{IMU.begin(0x6A);{{";
    if (dropdown_olc == "TempC")
    {
        var code = include+setup+'IMU.read'+dropdown_olc+'()';
    }
    else
    {
        var code = include+setup+'IMU.read'+dropdown_olc+dropdown_eksen+'()';
    }
    return [code, Order.NONE]
};
javascriptGenerator.forBlock['algi_hc'] = function(block, generator) {
    var dropdown_tetik = block.getFieldValue('tetik');
    var dropdown_eko = block.getFieldValue('eko');
    var include="½½#include <HCSR04.h>½½ ½½UltraSonicDistanceSensor distanceSensor_"+dropdown_tetik+"("+dropdown_tetik+","+dropdown_eko+");½½";
    var code = include+ 'distanceSensor_'+dropdown_tetik+'.measureDistanceCm()';
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['algi_dht'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var dropdown_sec = block.getFieldValue('sec');
    var include= "½½#include <DHT.h>½½ ½½DHT dht_"+dropdown_pin+"("+dropdown_pin+",DHT11);½½";
    var setup="{{dht_"+dropdown_pin+".begin();{{";
    var code = include+setup+'dht_'+dropdown_pin+'.read'+dropdown_sec+'()';
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['algi_mic'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var include="½½ #include <mp34dt05.h>½½ ½½int samples[BUFFER_SIZE];½½ ½½FilterBeHp2 filter;½½ ½½int "+value_name+";½½";
    var setup="{{micBegin();{{";
    var code = include+setup+'size_t num_bytes_read = 0;\nmicRead(&num_bytes_read, samples);\nif (num_bytes_read > 0) {\n'+value_name+'= dataFilter(filter, num_bytes_read, samples);\n}\n';
    return code;
  };
javascriptGenerator.forBlock['algi_manyetik'] = function(block, generator) {

    var code = 'hallRead()';
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['algi_tanimla'] = function(block, generator) {
    var dropdown_kazanc = block.getFieldValue('kazanc');
    var include="½½#include <Wire.h>½½ ½½#include <Adafruit_TCS34725.h>½½ ½½#include <Deneyap_RenkDonusturme.h>½½ ½½Adafruit_TCS34725 tcs34725 = Adafruit_TCS34725(TCS34725_INTEGRATIONTIME_50MS,TCS34725_GAIN_"+dropdown_kazanc+"X);½½ ½½double  tcs34725_r=0;½½½½double  tcs34725_g=0;½½½½double  tcs34725_b=0;½½½½double  tcs34725_h=0;½½½½double  tcs34725_s=0;½½½½double  tcs34725_v=0;½½½½uint16_t  tcs34725_clear=0;½½";
    var code = include+'{{tcs34725.begin();{{';
    return code;
  };
javascriptGenerator.forBlock['algi_oku'] = function(block, generator) {
    var include="½½void fnc_tcs34725_capturecolor(){½½½½uint16_t  tcs_red, tcs_green, tcs_blue;½½½½tcs34725.getRawData(&tcs_red, &tcs_green, &tcs_blue, &tcs34725_clear);½½½½if (tcs34725_clear == 0) {½½½½tcs34725_r=tcs34725_g=tcs34725_b=0;½½½½return;½½½½}½½½½tcs34725_r = ((float)tcs_red / (float)(tcs34725_clear)) * 255.0;½½½½tcs34725_g = ((float)tcs_green / (float)(tcs34725_clear)) * 255.0;½½½½tcs34725_b = ((float)tcs_blue / (float)(tcs34725_clear)) * 255.0;½½½½ColorConverter::RgbToHsv(static_cast<uint8_t>(tcs34725_r), static_cast<uint8_t>(tcs34725_g), static_cast<uint8_t>(tcs34725_b), tcs34725_h, tcs34725_s,tcs34725_v);½½½½tcs34725_h=tcs34725_h*360;½½½½tcs34725_s=tcs34725_s*100;½½½½tcs34725_v=tcs34725_v*100;}½½";
    var code = include+'fnc_tcs34725_capturecolor();\n';
    return code;
  };
javascriptGenerator.forBlock['algi_dogru'] = function(block, generator) {
  var dropdown_name = block.getFieldValue('NAME');
  var code = dropdown_name;
  return [code, Order.NONE]
};

javascriptGenerator.forBlock['algi_okunan'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var include="½½bool fnc_tcs34725_iscolor(int _color)½½½½{½½½½if(tcs34725_h > 340 || tcs34725_h < 20){ if(_color==2) return true; } //red½½½½else if(tcs34725_h < 45){ if(_color==3) return true; }  //orange½½½½else if(tcs34725_h < 70){ if(_color==4) return true; }  //yellow½½½½else if(tcs34725_h < 150){ if(_color==5) return true; } //green½½½½else if(tcs34725_h < 210){ if(_color==6) return true; } //cyan½½½½else if(tcs34725_h < 265){ if(_color==7) return true; } //blue½½½½else if(tcs34725_h < 340){ if(_color==8) return true; } //violet½½½½return false;½½½½}½½";
    var code = include+'fnc_tcs34725_iscolor('+dropdown_name+')\n';
    return [code, Order.NONE]
  };

//!   bluetooth generatörleri

javascriptGenerator.forBlock['blue_baslat'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var include="½½#include <BluetoothSerial.h>½½ ½½BluetoothSerial SerialBT;½½"
    var code = include+'{{SerialBT.begin('+value_name+');{{';
    return code;
  };
javascriptGenerator.forBlock['blue_hazir'] = function(block, generator) {
    var code = 'SerialBT.available()';
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['blue_yaz'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'SerialBT.write('+value_name+');\n';
    return code;
  };
javascriptGenerator.forBlock['blue_oku'] = function(block, generator) {
    var code = 'SerialBT.read()';
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['blue_goster'] = function(block, generator) {
  var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
  var code = 'SerialBT.println('+value_name+');\n';
  return code;
};

//!   deneyap led matris generatörleri
javascriptGenerator.forBlock['ledmatris_ayarla'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var text_name = block.getFieldValue('NAME');
    var include="½½#include <Deneyap_5x7LedEkran.h>½½ ½½DotMatrix LEDMatris"+dropdown_1+";½½";
    var code = include+'{{LEDMatris'+dropdown_1+'.begin('+text_name+');{{';
    return code;
  };
javascriptGenerator.forBlock['ledmatris_isik'] = function(block, generator) {
    var checkbox_11 = block.getFieldValue('11') === 'TRUE' ? '1' : '0';
    var checkbox_12 = block.getFieldValue('12') === 'TRUE' ? '1' : '0';
    var checkbox_13 = block.getFieldValue('13') === 'TRUE' ? '1' : '0';
    var checkbox_14 = block.getFieldValue('14') === 'TRUE' ? '1' : '0';
    var checkbox_15 = block.getFieldValue('15') === 'TRUE' ? '1' : '0';
    var checkbox_21 = block.getFieldValue('21') === 'TRUE' ? '1' : '0';
    var checkbox_22 = block.getFieldValue('22') === 'TRUE' ? '1' : '0';
    var checkbox_23 = block.getFieldValue('23') === 'TRUE' ? '1' : '0';
    var checkbox_24 = block.getFieldValue('24') === 'TRUE' ? '1' : '0';
    var checkbox_25 = block.getFieldValue('25') === 'TRUE' ? '1' : '0';
    var checkbox_31 = block.getFieldValue('31') === 'TRUE' ? '1' : '0';
    var checkbox_32 = block.getFieldValue('32') === 'TRUE' ? '1' : '0';
    var checkbox_33 = block.getFieldValue('33') === 'TRUE' ? '1' : '0';
    var checkbox_34 = block.getFieldValue('34') === 'TRUE' ? '1' : '0';
    var checkbox_35 = block.getFieldValue('35') === 'TRUE' ? '1' : '0';
    var checkbox_41 = block.getFieldValue('41') === 'TRUE' ? '1' : '0';
    var checkbox_42 = block.getFieldValue('42') === 'TRUE' ? '1' : '0';
    var checkbox_43 = block.getFieldValue('43') === 'TRUE' ? '1' : '0';
    var checkbox_44 = block.getFieldValue('44') === 'TRUE' ? '1' : '0';
    var checkbox_45 = block.getFieldValue('45') === 'TRUE' ? '1' : '0';
    var checkbox_51 = block.getFieldValue('51') === 'TRUE' ? '1' : '0';
    var checkbox_52 = block.getFieldValue('52') === 'TRUE' ? '1' : '0';
    var checkbox_53 = block.getFieldValue('53') === 'TRUE' ? '1' : '0';
    var checkbox_54 = block.getFieldValue('54') === 'TRUE' ? '1' : '0';
    var checkbox_55 = block.getFieldValue('55') === 'TRUE' ? '1' : '0';
    var checkbox_61 = block.getFieldValue('61') === 'TRUE' ? '1' : '0';
    var checkbox_62 = block.getFieldValue('62') === 'TRUE' ? '1' : '0';
    var checkbox_63 = block.getFieldValue('63') === 'TRUE' ? '1' : '0';
    var checkbox_64 = block.getFieldValue('64') === 'TRUE' ? '1' : '0';
    var checkbox_65 = block.getFieldValue('65') === 'TRUE' ? '1' : '0';
    var checkbox_71 = block.getFieldValue('71') === 'TRUE' ? '1' : '0';
    var checkbox_72 = block.getFieldValue('72') === 'TRUE' ? '1' : '0';
    var checkbox_73 = block.getFieldValue('73') === 'TRUE' ? '1' : '0';
    var checkbox_74 = block.getFieldValue('74') === 'TRUE' ? '1' : '0';
    var checkbox_75 = block.getFieldValue('75') === 'TRUE' ? '1' : '0';
    var satir1 = checkbox_11+','+checkbox_12+','+checkbox_13+','+checkbox_14+','+checkbox_15+',';
    var satir2 = checkbox_21+','+checkbox_22+','+checkbox_23+','+checkbox_24+','+checkbox_25+',';
    var satir3 = checkbox_31+','+checkbox_32+','+checkbox_33+','+checkbox_34+','+checkbox_35+',';
    var satir4 = checkbox_41+','+checkbox_42+','+checkbox_43+','+checkbox_44+','+checkbox_45+',';
    var satir5 = checkbox_51+','+checkbox_52+','+checkbox_53+','+checkbox_54+','+checkbox_55+',';
    var satir6 = checkbox_61+','+checkbox_62+','+checkbox_63+','+checkbox_64+','+checkbox_65+',';
    var satir7 = checkbox_71+','+checkbox_72+','+checkbox_73+','+checkbox_74+','+checkbox_75;
    var code=satir1+satir2+satir3+satir4+satir5+satir6+satir7;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['ledmatris_yak'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var value_satir = generator.valueToCode(block, 'satir', Order.NONE);
    var value_sutun = generator.valueToCode(block, 'sutun', Order.NONE);
    var code = 'LEDMatris'+dropdown_1+'.pixelLed('+value_satir+','+value_sutun+');\n';
    return code;
  };

javascriptGenerator.forBlock['ledmatris_satir'] = function(block, generator) {
  var dropdown_deger = block.getFieldValue('deger');
  var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
  var checkbox_1 = block.getFieldValue('1') === 'TRUE' ? '1' : '0';
  var checkbox_2 = block.getFieldValue('2') === 'TRUE' ? '1' : '0';
  var checkbox_3 = block.getFieldValue('3') === 'TRUE' ? '1' : '0';
  var checkbox_4 = block.getFieldValue('4') === 'TRUE' ? '1' : '0';
  var checkbox_5 = block.getFieldValue('5') === 'TRUE' ? '1' : '0';
  var code = 'LEDMatris'+dropdown_deger+'.dotrow'+value_name+'('+checkbox_1+','+checkbox_2+','+checkbox_3+','+checkbox_4+','+checkbox_5+');\n';
  return code;
};
javascriptGenerator.forBlock['ledmatris_sutun'] = function(block, generator) {
    var dropdown_deger = block.getFieldValue('deger');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var checkbox_1 = block.getFieldValue('1') === 'TRUE' ? '1' : '0';
    var checkbox_2 = block.getFieldValue('2') === 'TRUE' ? '1' : '0';
    var checkbox_3 = block.getFieldValue('3') === 'TRUE' ? '1' : '0';
    var checkbox_4 = block.getFieldValue('4') === 'TRUE' ? '1' : '0';
    var checkbox_5 = block.getFieldValue('5') === 'TRUE' ? '1' : '0';
    var checkbox_6 = block.getFieldValue('6') === 'TRUE' ? '1' : '0';
    var checkbox_7 = block.getFieldValue('7') === 'TRUE' ? '1' : '0';
    
    var code = 'LEDMatris'+dropdown_deger+'.dotcolumn'+value_name+'('+checkbox_1+','+checkbox_2+','+checkbox_3+','+checkbox_4+','+checkbox_5+','+checkbox_6+','+checkbox_7+');\n';
    return code;
  };

//! Deneyap oled ekran generatörleri

javascriptGenerator.forBlock['oled_ayarla'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var text_name = block.getFieldValue('NAME');
    var include="½½#include <Deneyap_OLED.h>½½ ½½OLED OLED"+dropdown_1+";½½";
    var code = include+'{{OLED'+dropdown_1+'.begin('+text_name+');{{ {{OLED'+dropdown_1+'.clearDisplay();{{';
    return code;
  };
  javascriptGenerator.forBlock['oled_goster'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var value_satir = generator.valueToCode(block, 'satir', Order.NONE);
    var value_sutun = generator.valueToCode(block, 'sutun', Order.NONE);
    var value_metin = generator.valueToCode(block, 'metin', Order.NONE);
    var code = 'OLED'+dropdown_1+'.setTextXY('+value_satir+','+value_sutun+');\nOLED'+dropdown_1+'.putString('+value_metin+');\n';
    return code;
  };
  javascriptGenerator.forBlock['oled_kayan'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_yon = block.getFieldValue('yon');
    var dropdown_hiz = block.getFieldValue('hiz');
    var code = 'OLED'+dropdown_1+'.setHorizontalScrollProperties('+dropdown_yon+',0,7,Scroll_'+dropdown_hiz+'Frames);\nOLED'+dropdown_1+'.activateScroll();\n';
    return code;
  };

  javascriptGenerator.forBlock['oled_veri'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var value_satir = generator.valueToCode(block, 'satir', Order.NONE);
    var value_sutun = generator.valueToCode(block, 'sutun', Order.NONE);
    var dropdown_veri = block.getFieldValue('veri');
    var value_degisken = generator.valueToCode(block, 'degisken', Order.NONE);
    var code =  'OLED'+dropdown_1+'.setTextXY('+value_satir+','+value_sutun+');\nOLED'+dropdown_1+dropdown_veri+value_degisken+');\n';
    return code;
  };
  javascriptGenerator.forBlock['oled_temizle'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = 'OLED'+dropdown_name+'.clearDisplay();\n';
    return code;
  };

// !  Deneyap algılayıcılar generatörleri

javascriptGenerator.forBlock['dealgi_basinc'] = function(block, generator) {
    var include="½½#include <Deneyap_BasincOlcer.h>½½ ½½AtmosphericPressure BaroSensor;½½";
    var setup="{{BaroSensor.begin(0x76);{{";
    var code = include+setup;
    return code;
  };

  javascriptGenerator.forBlock['dealgi_basincolcer'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['dealgi_sicaklik'] = function(block, generator) {
    var include="½½#include <Deneyap_SicaklikNemBasincOlcer.h>½½ ½½SHT4x TempHum;½½";
    var setup="{{TempHum.begin(0X44);{{"
    var code = include+setup+'TempHum.measure();';
    return code;
  };

javascriptGenerator.forBlock['dealgi_sicaklikbirim'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Order.NONE]
  };

  javascriptGenerator.forBlock['dealgi_sicaklikoku'] = function(block, generator) {
    var code = 'TempHum.RHtoPercent()';
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['dealgi_3eksen'] = function(block, generator) {
    var include="½½#include <Deneyap_9EksenAtaletselOlcumBirimi.h>½½ ½½MAGNETOMETER Magne;½½";
    var setup="{{Magne.begin(0x60);{{";
    var code = include+setup+'Magne.RegRead();';
    return code;
  };
javascriptGenerator.forBlock['dealgi_kumpas'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_6eksen'] = function(block, generator) {
    var dropdown_tanimla = block.getFieldValue('tanimla');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_6EksenAtaletselOlcumBirimi.h>½½ ½½LSM6DSM AccGyro"+dropdown_tanimla+";½½";
    var setup="{{AccGyro"+dropdown_tanimla+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_donu'] = function(block, generator) {
    var dropdown_oku = block.getFieldValue('oku');
    var dropdown_eksen = block.getFieldValue('eksen');
    var code = "AccGyro"+dropdown_oku+".readFloatGyro"+dropdown_eksen+"()";
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['dealgi_ivme'] = function(block, generator) {
  var dropdown_oku = block.getFieldValue('oku');
  var dropdown_eksen = block.getFieldValue('eksen');
  var code = "AccGyro"+dropdown_oku+".readFloatAccel"+dropdown_eksen+"()";
  return [code, Order.NONE]
};

javascriptGenerator.forBlock['dealgi_ataletsel'] = function(block, generator) {
    var dropdown_sicaklik = block.getFieldValue('sicaklik');
    var dropdown_birim = block.getFieldValue('birim');
    var code = "AccGyro"+dropdown_sicaklik+".readTemp"+dropdown_birim+"()";
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['dealgi_hareket'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var include="½½#include <Deneyap_HareketIsikRenkAlgilayiciMesafeOlcer.h>½½ ½½APDS9960 Algilama;½½";
    var setup="{{Algilama.begin(0x39);{{";
    var code="";
    if (dropdown_name== "hareket")
    {
        setup = setup + "{{Algilama.enableProximity(true);{{ {{Algilama.enableGesture(true);{{";
    }
    else if (dropdown_name== "mesafe")
    {
        setup = setup + "{{Algilama.enableProximity(true);{{";
    }
    else
    {
        setup = setup + "{{Algilama.enableColor(true);{{";
        code =  "uint16_t r, g, b, c; \nwhile(!Algilama.colorDataReady()){\ndelay(5);\n}\nAlgilama.getColorData(&r, &g, &b, &c);";
    }
    code = include + setup +code;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_yakinlik'] = function(block, generator) {
    var code = 'Algilama.readProximity()';
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_harekettespit'] = function(block, generator) {
    var code = 'Algilama.readGesture()';
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_yon'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_renk'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code =dropdown_name;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_derinlik'] = function(block, generator) {
    var include="½½#include <Deneyap_DerinlikOlcer.h>½½ ½½TofRangeFinder TofRangeFinder;½½";
    var setup="{{TofRangeFinder.begin(0x29);{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_derinlikolc'] = function(block, generator) {
    var code = 'TofRangeFinder.ReadDistance()';
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_duman'] = function(block, generator) {
    var dropdown_dedektor = block.getFieldValue('dedektor');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_DumanDedektoru.h>½½ ½½SmokeDetector DumanSensor"+dropdown_dedektor+";½½";
    var setup="{{DumanSensor"+dropdown_dedektor+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_dumanalgila'] = function(block, generator) {
    var dropdown_duman = block.getFieldValue('duman');
    var dropdown_tip = block.getFieldValue('tip');
    var code = "DumanSensor"+dropdown_duman+".ReadSmoke"+dropdown_tip+"()";
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_kumanda'] = function(block, generator) {
    var dropdown_tanimla = block.getFieldValue('tanimla');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_KumandaKolu.h>½½ ½½Joystick KumandaKolu"+dropdown_tanimla+";½½";
    var setup="{{KumandaKolu1.begin(0x1A);{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_kumandakontrol'] = function(block, generator) {
    var dropdown_kumanda = block.getFieldValue('kumanda');
    var dropdown_tip = block.getFieldValue('tip');
    var code = "KumandaKolu"+dropdown_kumanda+dropdown_tip;
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['dealgi_cift'] = function(block, generator) {
  var dropdown_tanimla = block.getFieldValue('tanimla');
  var text_name = block.getFieldValue('NAME');
  var include="½½#include <Deneyap_CiftKanalliCizgiAlgilama.h>½½ ½½LineFollower CizgiAlgilamaSensor"+dropdown_tanimla+";½½";
  var setup="{{CizgiAlgilamaSensor"+dropdown_tanimla+".begin("+text_name+");{{";
  var code = include+setup;
  return code;
};
javascriptGenerator.forBlock['dealgi_cizgi'] = function(block, generator) {
    var dropdown_cizgi = block.getFieldValue('cizgi');
    var dropdown_tip = block.getFieldValue('tip');
    var dropdown_algi = block.getFieldValue('algi');
    var code = "CizgiAlgilamaSensor"+dropdown_cizgi+".ReadLineFollower"+dropdown_tip+dropdown_algi+"()";
    return [code, Order.NONE]
  };

  javascriptGenerator.forBlock['dealgi_mesafe'] = function(block, generator) {
    var include="½½#include <Deneyap_MesafeOlcerIsikAlgilayici.h>½½ ½½ProximityAL YakinIsik;½½";
    var setup="{{YakinIsik.begin(0x23);{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_yakinlikisik'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_yagmur'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var text_name = block.getFieldValue('NAME');
    var include="½½#include <Deneyap_YagmurAlgilama.h>½½ ½½Rain YagmurSensor1;½½";
    var setup="{{YagmurSensor"+dropdown_1+".begin("+text_name+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_yagmurveri'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var dropdown_tip = block.getFieldValue('tip');
    var code = "YagmurSensor"+dropdown_name+".ReadRain"+dropdown_tip+"()";
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_role'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_Role.h>½½ ½½Relay Role"+dropdown_name+";½½";
    var setup="{{Role"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_rolecalistir'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var dropdown_durum = block.getFieldValue('durum');
    var code = "Role"+dropdown_name+".RelayDrive("+dropdown_durum+");";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_toprak'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_ToprakNemiOlcer.h>½½ ½½SoilMoisture ToprakNemi"+dropdown_name+";½½";
    var setup="{{ToprakNemi"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_toprakolc'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = "ToprakNemi"+dropdown_name+".ReadSoilMoisture()";
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_dokunmatik'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_DokunmatikTusTakimi.h>½½ ½½Keypad TusTakimi"+dropdown_name+";½½";
    var setup="{{TusTakimi"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
javascriptGenerator.forBlock['dealgi_tus'] = function(block, generator) {
  var dropdown_name = block.getFieldValue('NAME');
  var code = "TusTakimi"+dropdown_name+".KeypadRead()";
  return [code, Order.NONE]
};
javascriptGenerator.forBlock['dealgi_kizil'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_KizilotesiAliciVerici.h>½½ ½½Transceiver IR"+dropdown_name+";½½";
    var setup="{{IR"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_kiziloku'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = "IR"+dropdown_name+".transceiverRead();\n";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_kizilgonder'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_sinyal = block.getFieldValue('sinyal');
    var code = "IR"+dropdown_name+".transceiverWrite("+text_sinyal+");";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_hareketadres'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_HareketAlgilama.h>½½ ½½Gesture Hareket"+dropdown_name+";½½";
    var setup="{{Hareket"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_hareketalgila'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code =  "Hareket"+dropdown_name+".readGesture()";
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_zaman'] = function(block, generator) {
    var value_gun = generator.valueToCode(block, 'gun', Order.NONE);
    var value_ay = generator.valueToCode(block, 'ay', Order.NONE);
    var value_yil = generator.valueToCode(block, 'yil', Order.NONE);
    var value_saat = generator.valueToCode(block, 'saat', Order.NONE);
    var value_dakika = generator.valueToCode(block, 'dakika', Order.NONE);
    var value_saniye = generator.valueToCode(block, 'saniye', Order.NONE);
    var include="½½#include <Deneyap_GercekZamanliSaat.h>½½ ½½RTC GercekZamanliSaat;½½";
    var code = include+ "{{GercekZamanliSaat.adjust(DateTime("+value_yil+","+value_ay+","+value_gun+","+value_saat+","+value_dakika+","+value_saniye+"));{{";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_simdiki'] = function(block, generator) {
    var include="½½#include <Deneyap_GercekZamanliSaat.h>½½ ½½RTC GercekZamanliSaat;½½";
    var code = include+ "DateTime now = GercekZamanliSaat.now();";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_zamangoster'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var include="½½#include <Deneyap_GercekZamanliSaat.h>½½ ½½RTC GercekZamanliSaat;½½";
    var setup="{{GercekZamanliSaat.begin();{{ {{GercekZamanliSaat.deviceStart();{{ {{GercekZamanliSaat.adjust();{{";
    var code = include+setup+"DateTime now = GercekZamanliSaat.now();\n"+dropdown_name;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_zamantarih'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    if(dropdown_name=="tarih")
    {
        var include="½½#include <Deneyap_GercekZamanliSaat.h>½½ ½½RTC GercekZamanliSaat;½½ ½½String tarih(const DateTime& dt){½½ ½½char rtcdate[12];½½ ½½sprintf(rtcdate,'%02d/%02d/%04d', dt.day(), dt.month(), dt.year());½½ ½½return String(rtcdate);½½ ½½}½½";
    }
    else
    {
        var include="½½#include <Deneyap_GercekZamanliSaat.h>½½ ½½RTC GercekZamanliSaat;½½ ½½String saat(const DateTime& dt){½½ ½½char rtctime[10];½½ ½½sprintf(rtcdate,'%02d/%02d/%04d', dt.hour(), dt.minute(), dt.second());½½ ½½return String(rtctime);½½ ½½}½½";
    }
    var setup="{{GercekZamanliSaat.begin();{{ {{GercekZamanliSaat.deviceStart();{{ {{GercekZamanliSaat.adjust();{{";
    var code = include+setup+dropdown_name+"(now)";
    return [code, Order.NONE]
  };

  javascriptGenerator.forBlock['dealgi_dc'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_CiftKanalliMotorSurucu.h>½½ ½½DualMotorDriver MotorSurucu"+dropdown_name+";½½";
    var setup="{{MotorSurucu"+dropdown_name+".begin("+text_adres+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_dcsur'] = function(block, generator) {
    var dropdown_sur = block.getFieldValue('sur');
    var dropdown_kanal = block.getFieldValue('kanal');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_yon = block.getFieldValue('yon');

    var code = "MotorSurucu"+dropdown_sur+".MotorDrive("+dropdown_kanal+","+value_name+","+dropdown_yon+");";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_dcsurucu'] = function(block, generator) {
    var dropdown_sur = block.getFieldValue('sur');
    var dropdown_durum = block.getFieldValue('durum');
    var code = "MotorSurucu"+dropdown_sur+".StandbyMode("+dropdown_durum+");\n";
    return code;
  };

  javascriptGenerator.forBlock['dealgi_step'] = function(block, generator) {
    var dropdown_sur = block.getFieldValue('sur');
    var text_adres = block.getFieldValue('adres');
    var value_tur = generator.valueToCode(block, 'tur', Order.NONE);
    var value_hiz = generator.valueToCode(block, 'hiz', Order.NONE);
    var include="½½#include <Deneyap_CiftKanalliMotorSurucu.h>½½ ½½const int stepsPerRevolution = "+value_tur+";½½ ½½Stepper myStepper"+dropdown_sur+"(stepsPerRevolution);½½";
    var setup="{{myStepper"+dropdown_sur+".begin("+text_adres+");{{ {{myStepper"+dropdown_sur+".setSpeed("+value_hiz+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_stepsur'] = function(block, generator) {
    var dropdown_sur = block.getFieldValue('sur');
    var value_adim = generator.valueToCode(block, 'adim', Order.NONE);
    var dropdown_yon = block.getFieldValue('yon');
    var code = "myStepper"+dropdown_sur+".step("+dropdown_yon+value_adim+");\n";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_stephiz'] = function(block, generator) {
    var dropdown_sur = block.getFieldValue('sur');
    var value_hiz = generator.valueToCode(block, 'hiz', Order.NONE);
    var code = "myStepper"+dropdown_sur+".setSpeed("+value_hiz+");";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_uv'] = function(block, generator) {
    var dropdown_tip = block.getFieldValue('tip');
    var include="½½#include <Deneyap_UltraviyoleIsikAlgilici.h>½½ ½½UVlight UVlight;½½";
    var setup="{{UVlight.begin(0x53);{{ {{UVlight.setMode(LTR390_MODE_"+dropdown_tip+");{{";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_uvalgila'] = function(block, generator) {
    var dropdown_tip = block.getFieldValue('tip');
    var code = dropdown_tip;
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_mikrofon'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var text_adres = block.getFieldValue('adres');
    var include= "½½#include <Deneyap_Mikrofon.h>½½ ½½Microphone Mikrofon"+dropdown_name+";½½";
    var setup="Mikrofon"+dropdown_name+".begin("+text_adres+");";
    var code = include+setup;
    return code;
  };
  javascriptGenerator.forBlock['dealgi_ses'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = "Mikrofon"+dropdown_name+".ReadMicrophoneAnalog()";
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['dealgi_gps'] = function(block, generator) {
    var dropdown_gps = block.getFieldValue('gps');
    var text_adres = block.getFieldValue('adres');
    var include="½½#include <Deneyap_GPSveGLONASSkonumBelirleyici.h>½½ ½½GPS GPS"+dropdown_gps+";½½";
    var setup="{{GPS"+dropdown_gps+".begin("+text_adres+");{{";
    var code = include+setup+"GPS"+dropdown_gps+".readGPS(RMC);";
    return code;
  };
  javascriptGenerator.forBlock['dealgi_konum'] = function(block, generator) {
    var dropdown_gps = block.getFieldValue('gps');
    var dropdown_tip = block.getFieldValue('tip');
    var code = "GPS"+dropdown_gps+dropdown_tip;
    return [code, Order.NONE]
  };










// !  dizi generatörleri















// !  kızılötesi generatörleri

javascriptGenerator.forBlock['kizil_oku'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var include="½½#include <IRremote.h>½½½½IRrecv ir_rx("+dropdown_name+");½½½½decode_results ir_rx_results;½½½½unsigned long fnc_ir_rx_decode()½½½½{½½½½bool decoded=false;½½½½if( ir_rx.decode(&ir_rx_results))½½½½{½½½½decoded=true;½½½½ir_rx.resume();½½½½}½½½½if(decoded)½½½½return ir_rx_results.value;½½½½else½½½½return 0;½½½½}½½";
    var setup="{{ir_rx.enableIRIn();{{";
    var code = include+setup+'(unsigned long)fnc_ir_rx_decode()';
    return [code, Order.NONE]
  };

 // !  LCD I2c generatörleri 

 javascriptGenerator.forBlock['lcd_ekran'] = function(block, generator) {
    var dropdown_ekran = block.getFieldValue('ekran');
    var text_adres = block.getFieldValue('adres');
    var value_sutun = generator.valueToCode(block, 'sutun', Order.NONE);
    var value_satir = generator.valueToCode(block, 'satir', Order.NONE);
    var include="½½#include <Wire.h>½½ ½½#include <LiquidCrystal_I2C.h>½½ ½½LiquidCrystal_I2C lcd"+dropdown_ekran+"("+text_adres+","+value_sutun+","+value_satir+");½½";
    var code = include+'{{lcd'+dropdown_ekran+'.init();{{ {{lcd'+dropdown_ekran+'.backlight();{{';
    return code;
  };
javascriptGenerator.forBlock['lcd_imlec'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var value_2 = generator.valueToCode(block, '2', Order.NONE);
    var code = 'lcd'+dropdown_name+'.setCursor('+value_1+','+value_2+');\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_goster'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var code = 'lcd'+dropdown_name+'.print('+value_1+');\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_yaz'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_1 = generator.valueToCode(block, '1', Order.NONE);
    var code = 'lcd'+dropdown_name+'.write('+value_1+');\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_temizle'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = 'lcd'+dropdown_name+'.clear();\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_ev'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = 'lcd'+dropdown_name+'.home();\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_display'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var display = block.getFieldValue('display');
    var code = 'lcd'+dropdown_name+'.'+display+'();\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_kaydir'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var kaydir = block.getFieldValue('kaydir');
    var code = 'lcd'+dropdown_name+'.scrollDisplay'+kaydir+'();\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_arka'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var isik = block.getFieldValue('isik');
    var code = 'lcd'+dropdown_name+isik+'\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_imlecgoster'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var imlec = block.getFieldValue('imlec');
    var code = 'lcd'+dropdown_name+imlec+'\n';
    return code;
  };
  javascriptGenerator.forBlock['lcd_imlecyan'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var imlec = block.getFieldValue('imlec');
    var code = 'lcd'+dropdown_name+imlec+'\n';
    return code;
  };

 // ! Nesnelerin interneti generatörleri 













 // ! RFID generatörleri 

 javascriptGenerator.forBlock['rfid_tanimla'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_2 = block.getFieldValue('2');
    var include="½½#include <SPI.h>½½ ½½#include <MFRC522.h>½½ ½½MFRC522 mfrc522("+dropdown_1+","+dropdown_2+");½½";
    var code = include+'{{SPI.begin();{{ {{mfrc522.PCD_Init();{{';
    return code;
  };
javascriptGenerator.forBlock['rfid_oku'] = function(block, generator) {
    var include="½½String fnc_user_ID(){½½½½ mfrc522.PICC_ReadCardSerial();½½½½String user_ID="+'""'+";½½½½for (byte i=0; i<mfrc522.uid.size; i++){½½½½user_ID += (mfrc522.uid.uidByte[i] < 0x10 ? '0': ' ') + String(mfrc522.uid.uidByte[i], HEX);½½ ½½}½½ ½½return user_ID;½½½½}½½";
    var code = include+'fnc_user_ID()';
    return [code, Order.NONE]
  };
  javascriptGenerator.forBlock['rfid_algi'] = function(block, generator) {
    var code = 'mfrc522.PICC_IsNewCardPresent()';
    return [code, Order.NONE]
  };

// ! RGB generatörleri 

javascriptGenerator.forBlock['rgb_yan'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var setup="{{pinMode(LEDB, OUTPUT);{{";
    var code = setup+'digitalWrite(LEDB, HIGH);\ndelay('+dropdown_name+');\ndigitalWrite(LEDB, LOW);\ndelay('+dropdown_name+');\n';
    return code;
  };

  javascriptGenerator.forBlock['rgb_rgb'] = function(block, generator) {
    var value_kirmizi = generator.valueToCode(block, 'kirmizi', Order.NONE);
    var value_yesil = generator.valueToCode(block, 'yesil', Order.NONE);
    var value_mavi = generator.valueToCode(block, 'mavi', Order.NONE);
    var setup="{{pinMode(LEDR, OUTPUT);{{ {{pinMode(LEDG, OUTPUT);{{{{pinMode(LEDB, OUTPUT);{{";
    var code = setup+'digitalWrite(LEDR,'+value_kirmizi+');\ndigitalWrite(LEDG,'+value_yesil+');\ndigitalWrite(LEDB,'+value_mavi+');\n';
    return code;
  };
  javascriptGenerator.forBlock['rgb_adres'] = function(block, generator) {
    var value_kirmizi = generator.valueToCode(block, 'kirmizi', Order.NONE);
    var value_yesil = generator.valueToCode(block, 'yesil', Order.NONE);
    var value_mavi = generator.valueToCode(block, 'mavi', Order.NONE);
    var setup="{{pinMode(RGBLED, OUTPUT);{{ ";
    var code = setup+'neopixelWrite(RGBLED,'+value_kirmizi+','+value_yesil+','+value_mavi+');\n';
    return code;
  };
javascriptGenerator.forBlock['rgb_tanimla'] = function(block, generator) {
  var dropdown_tanim = block.getFieldValue('tanim');
  var dropdown_kirmizi = block.getFieldValue('kirmizi');
  var dropdown_yesil = block.getFieldValue('yesil');
  var dropdown_mavi = block.getFieldValue('mavi');
  var include="½½#define kirmiziPin"+dropdown_tanim+" "+dropdown_kirmizi+"½½ ½½#define yesilPin"+dropdown_tanim+" "+dropdown_yesil+"½½ ½½#define maviPin"+dropdown_tanim+" "+dropdown_mavi+"½½";
  var code = include+'{{pinMode('+dropdown_kirmizi+', OUTPUT);{{ {{pinMode('+dropdown_yesil+', OUTPUT);{{ {{pinMode('+dropdown_mavi+', OUTPUT);{{';
  return code;
};

javascriptGenerator.forBlock['rgb_yak'] = function(block, generator) {
    var dropdown_tanim = block.getFieldValue('tanim');
    var value_kirmizi = generator.valueToCode(block, 'kirmizi', Order.NONE);
    var value_yesil = generator.valueToCode(block, 'yesil', Order.NONE);
    var value_mavi = generator.valueToCode(block, 'mavi', Order.NONE);
    var code =  'digitalWrite(kirmiziPin'+dropdown_tanim+','+value_kirmizi+');\ndigitalWrite(yesilPin'+dropdown_tanim+','+value_yesil+');\ndigitalWrite(maviPin'+dropdown_tanim+','+value_mavi+');\n';
    return code;
  };
javascriptGenerator.forBlock['rgb_sec'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var colour_renk = block.getFieldValue('renk');
    colour_renk = colour_renk.substring(1);
    var r = parseInt(colour_renk.substring(0, 2), 16);
    var g = parseInt(colour_renk.substring(2, 4), 16);
    var b = parseInt(colour_renk.substring(4, 6), 16);
    var code =  'digitalWrite(kirmiziPin'+dropdown_name+','+r+');\ndigitalWrite(yesilPin'+dropdown_name+','+g+');\ndigitalWrite(maviPin'+dropdown_name+','+b+');\n';
    return code;
  };

// ! Saat generatörleri 

javascriptGenerator.forBlock['saat_zamanayarla'] = function(block, generator) {
    var value_yil = generator.valueToCode(block, 'yil', Order.NONE);
    var value_ay = generator.valueToCode(block, 'ay', Order.NONE);
    var value_gun = generator.valueToCode(block, 'gun', Order.NONE);
    var value_saat = generator.valueToCode(block, 'saat', Order.NONE);
    var value_dakika = generator.valueToCode(block, 'dakika', Order.NONE);
    var value_saniye = generator.valueToCode(block, 'saniye', Order.NONE);
    var code = '½½#include "Wire.h½½ ½½#include "RTClib.h½½ ½½RTC_DS3231 rtc;½½'+'rtc.adjust(DateTime('+value_yil+','+value_ay+','+value_gun+','+value_saat+','+value_dakika+','+value_saniye+'));\n';
    // kutuphane = kutuphane + ;
    return code;
};

javascriptGenerator.forBlock['saat_zamanyenile'] = function(block, generator) {
    var code = 'DateTime now = rtc.now();\n';
    var include= '½½#include "Wire.h½½ ½½#include "RTClib.h½½ ½½RTC_DS3231 rtc;½½';
    var setup=  "{{if (! rtc.begin()){Serial.println(Couldnt find RTC);while (1);}rtc.adjust(DateTime(__DATE__, __TIME__));}{{";
    var code= include+setup+"DateTime now = rtc.now();";
    return code;
};
javascriptGenerator.forBlock['saat_zamangosteryil'] = function(block, generator) {
    var include= '½½#include "Wire.h½½ ½½#include "RTClib.h½½ ½½RTC_DS3231 rtc;½½';
    var setup=  "{{if (! rtc.begin()){Serial.println(Couldnt find RTC);while (1);}rtc.adjust(DateTime(__DATE__, __TIME__));}{{";
    var dropdown_name = block.getFieldValue('NAME');
    var code =include+setup+ 'now.'+dropdown_name+'()';
    return [code, Order.NONE];
  };
javascriptGenerator.forBlock['saat_tarihgoster'] = function(block, generator) {
    var include='½½#include "Wire.h"½½ ½½#include "RTClib.h"½½ ½½RTC_DS3231 rtc;String allDate(const DateTime& dt){char rtcdate[12];sprintf(rtcdate,"%02d/%02d/%04d", dt.day(), dt.month(), dt.year());return String(rtcdate);}½½';
    var setup=  "{{if (! rtc.begin()){Serial.println(Couldnt find RTC);while (1);}rtc.adjust(DateTime(__DATE__, __TIME__));}{{";
    var code = include+setup+ 'allDate(now)';
    return [code, Order.NONE];
  };

javascriptGenerator.forBlock['saat_zamangoster'] = function(block, generator) {
    var include='½½#include "Wire.h"½½ ½½#include "RTClib.h"½½ ½½RTC_DS3231 rtc;String allTime(const DateTime& dt){char rtctime[10];sprintf(rtctime,"%02d:%02d:%02d", dt.hour(), dt.minute(), dt.second());return String(rtctime);}½½';
    var setup=  "{{if (! rtc.begin()){Serial.println(Couldnt find RTC);while (1);}rtc.adjust(DateTime(__DATE__, __TIME__));}{{";
    var code = include+setup+'allTime(now)';
    return [code, Order.NONE];
};

// ! Sd kart generatörleri 

javascriptGenerator.forBlock['sd_baslat'] = function(block, generator) {
    var include="½½#include <FS.h>½½ ½½#include <SD.h>½½ ½½#include <SPI.h>½½";
    var code = include+'{{SDCard.begin();{{';
    return code;
  };

  javascriptGenerator.forBlock['sd_yaz'] = function(block, generator) {
    var dosya = generator.valueToCode(block, '1', Order.NONE);
    var metin = generator.valueToCode(block, '2', Order.NONE);
    //kütüphane
    var include="½½void appendFile(fs::FS &fs, const char * path, const char * message){½½½½Serial.printf("+'"Dosyaya eklendi: %s"'+", path);½½½½File file = fs.open(path, FILE_APPEND);½½½½if(!file){½½½½Serial.println("+'"Ekleme islemi icin dosya acilamadi"'+");½½½½return;½½½½}½½½½if(file.print(message)){½½½½Serial.println("+'"Ekleme islemi basarili"'+");½½½½} else {½½½½Serial.println("+'"Ekleme islemi basarisiz"'+");½½½½}½½½½file.close();½½½½}½½";
    var code = include+"appendFile(SDCard,"+dosya+", String("+metin+").c_str());\n";
    return code;
  };
  javascriptGenerator.forBlock['sd_olustur'] = function(block, generator) {
    var dosya = generator.valueToCode(block, '1', Order.NONE);
    var include="½½void writeFile(fs::FS &fs, const char * path, const char * message){½½½½Serial.printf("+'"Dosyaya eklendi: %s"'+", path);½½½½File file = fs.open(path, FILE_WRITE);½½½½if(!file){½½½½Serial.println("+'"Yazma islemi icin dosya acilamadi"'+");½½½½return;½½½½}½½½½if(file.print(message)){½½½½Serial.println("+'"Ekleme islemi basarili"'+");½½½½} else {½½½½Serial.println("+'"Ekleme islemi basarisiz"'+");½½½½}½½½½file.close();½½½½}½½";

    var code = include+'{{writeFile(SDCard,'+dosya+',"");{{';
    return code;
  };

// ! seri iletişim generatörleri 

javascriptGenerator.forBlock['seri_baslat'] = function(block, generator) {
    var dropdown_hiz = block.getFieldValue('hiz');
    var code = '{{Serial.begin('+dropdown_hiz+');{{';
    return code;
  };

javascriptGenerator.forBlock['seri_hazir'] = function(block, generator) {
    var code = 'Serial.available()';
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['seri_goster'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'Serial.println('+value_name+');\n';
    return code;
  };
javascriptGenerator.forBlock['seri_oku'] = function(block, generator) {

    var code = 'Serial.read()';
    return [code, Order.NONE]
  };
javascriptGenerator.forBlock['seri_yaz'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'Serial.write('+value_name+');\n';
    return code;
  };

javascriptGenerator.forBlock['seri_habertanimla'] = function(block, generator) {
    var include="½½#include <Wire.h>½½"
    var code = include+'{{Wire.begin();{{';
    return code;
  };
javascriptGenerator.forBlock['seri_haberbaslat'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'Wire.beginTransmission('+value_name+');\n';
    return code;
  };
javascriptGenerator.forBlock['seri_haberbitir'] = function(block, generator) {
    var code = 'Wire.endTransmission();\n';
    return code;
  };
javascriptGenerator.forBlock['seri_haberdurdur'] = function(block, generator) {
    var code = 'Wire.endTransmission()';
    return [code, Order.NONE]
  };

// ! servo generatörleri 

javascriptGenerator.forBlock['servo_dondur'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var dropdown_kanal = block.getFieldValue('kanal');
    var value_aci = generator.valueToCode(block, 'aci', Order.NONE);
    var value_bekle = generator.valueToCode(block, 'bekle', Order.NONE);
    var include="½½#include <Deneyap_Servo.h>½½ ½½Servo servo_D0;½½";
    var setup="{{servo_"+dropdown_pin+".attach("+dropdown_pin+","+dropdown_kanal+");{{";

    var code = include+setup+'servo_'+dropdown_pin+'.write'+'('+value_aci+');\ndelay('+value_bekle+');\n';
    return code;
  };

// ! ses generatörleri 

javascriptGenerator.forBlock['ses_cal'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var value_hz = generator.valueToCode(block, 'Hz', Order.NONE);
    var value_sure = generator.valueToCode(block, 'sure', Order.NONE);
    var code = 'tone('+dropdown_pin+','+value_hz+','+value_sure+');\n';
    return code;
  };

javascriptGenerator.forBlock['ses_notacal'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var dropdown_nota = block.getFieldValue('nota');
    var dropdown_sure = block.getFieldValue('sure');
    var code = 'tone('+dropdown_pin+','+dropdown_nota+','+dropdown_sure+');\n';
    return code;
  };

javascriptGenerator.forBlock['ses_sustur'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var code = 'noTone('+dropdown_pin+');\n';
    return code;
  };

javascriptGenerator.forBlock['ses_nota'] = function(block, generator) {
    var dropdown_nota = block.getFieldValue('nota');
    var code = dropdown_nota;
    return [code, Order.NONE]
  };

javascriptGenerator.forBlock['ses_hoparlor'] = function(block, generator) {
    var dropdown_pin = block.getFieldValue('pin');
    var include="½½#include <Deneyap_Hoparlor.h>½½ ½½Speaker Speaker("+dropdown_pin+", 0);½½";
    var code = include+'Speaker.FillBuffer();\n';
    return code;
  };
javascriptGenerator.forBlock['ses_muzik'] = function(block, generator) {
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_enstruman = block.getFieldValue('Enstruman');
    var include="½½MusicScore Music("+value_name+", TEMPO_ALLEGRO, INSTRUMENT_"+dropdown_enstruman+");½½"
    var setup="{{Music.Repeat=1;{{ {{Speaker.Play(&Music);{{";
    var code = include+setup;
    return code;
  };

// ! step motor generatörleri 

javascriptGenerator.forBlock['step_lm298tanimla'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_2 = block.getFieldValue('2');
    var dropdown_3 = block.getFieldValue('3');
    var dropdown_4 = block.getFieldValue('4');
    var dropdown_5 = block.getFieldValue('5');
    var dropdown_6 = block.getFieldValue('6');
    var include="½½int l298n_ena="+dropdown_1+";½½"+ "½½int l298n_enb="+dropdown_2+";½½"+"½½int l298n_in1="+dropdown_3+";½½" +"½½int l298n_in2="+dropdown_4+";½½"+ "½½int l298n_in3="+dropdown_5+";½½" +"½½int l298n_in4="+dropdown_6+";½½";
    var setup="";
    setup+= "{{pinMode("+dropdown_1+", OUTPUT);{{";
    setup+= "{{pinMode("+dropdown_2+", OUTPUT);{{";
    setup+= "{{pinMode("+dropdown_3+", OUTPUT);{{";
    setup+= "{{pinMode("+dropdown_4+", OUTPUT);{{";
    setup+= "{{pinMode("+dropdown_5+", OUTPUT);{{";
    setup+= "{{pinMode("+dropdown_6+", OUTPUT);{{";
    var code=include+setup;
    return code;
};

javascriptGenerator.forBlock['step_lm298nsur'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_2 = block.getFieldValue('2');
    var value_hiz = generator.valueToCode(block, 'hiz', Order.NONE);
    switch (dropdown_1) {
        case "ileri":
            var bir = "LOW";
            var iki = "HIGH";
          break;
        case "geri":
            var bir = "HIGH";
            var iki = "LOW";
          break;
    }
    switch (dropdown_2) {
        case "A":
            var giris1 = "1";
            var giris2 = "2";
            break;
        case "B":
            var giris1 = "3";
            var giris2 = "4";
            break;
    }
    var code = 'digitalWrite(l298n_in'+giris1+','+bir+');\ndigitalWrite(l298n_in'+giris2+','+iki+');\nanalogWrite(l298n_ena,('+value_hiz+'));\n';
    return code;
};
javascriptGenerator.forBlock['step_kurulum'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_2 = block.getFieldValue('2');
    var dropdown_3 = block.getFieldValue('3');
    var dropdown_4 = block.getFieldValue('4');
    var code = '½½#include <Stepper.h>½½ ½½Stepper stepper_'+dropdown_name+'('+value_name+','+dropdown_1+','+dropdown_2+','+dropdown_3+','+dropdown_4+');½½';
    return code;
  };
javascriptGenerator.forBlock['step_sur'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'stepper_'+dropdown_name+'.setSpeed('+value_name+');\n';
    return code;
};
javascriptGenerator.forBlock['step_adim'] = function(block, generator) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    var code = 'stepper_'+dropdown_name+'.step('+value_name+');\n';
    return code;
};

// ! tuş generatörleri 

javascriptGenerator.forBlock['tus_3x4'] = function(block, generator) {
    var dropdown_1 = block.getFieldValue('1');
    var dropdown_2 = block.getFieldValue('2');
    var dropdown_3 = block.getFieldValue('3');
    var dropdown_4 = block.getFieldValue('4');
    var dropdown_5 = block.getFieldValue('5');
    var dropdown_6 = block.getFieldValue('6');
    var dropdown_7 = block.getFieldValue('7');
    //kütüphane ekleniyor.
    var code = "½½#include <Keypad.h>½½ ½½const int satir = 4;½½ ½½const int sutun = 3;½½ ½½char tuslar[satir][sutun] = {½½ ½½{'1','2','3'},½½ ½½{'4','5','6'},½½ ½½{'7','8','9'},½½ ½½{'*','0','#'}½½ ½½};½½ ½½byte satir_pinleri[satir] = {"+dropdown_1+","+dropdown_2+","+dropdown_3+","+dropdown_4+"};½½ ½½byte sutun_pinleri[sutun] = {"+dropdown_5+","+dropdown_6+","+dropdown_7+"};½½ ½½Keypad keypad = Keypad(makeKeymap(tuslar), satir_pinleri, sutun_pinleri, satir, sutun);½½";
    return code;
};
javascriptGenerator.forBlock['tus_basilan'] = function(block, generator) {

    var code = 'keypad.getKey()';
    return [code, Order.NONE]
};



}  
export default customBlocks;